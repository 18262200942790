import { Fragment, FunctionComponent, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';

import { SERVICE_TYPES } from 'app/constants';
import { ServiceDetailsData } from 'app/types/serviceDetails';

import { ServiceDetailItem } from './ServiceDetailItem';

const useStyles = makeStyles()(theme => ({
  divider: {
    width: `calc(100% - ${theme.spacing(2)})`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(-1),

    '&:last-of-type': {
      display: 'none'
    }
  }
}));

export const ServiceDetailGroup: FunctionComponent<{
  services: ServiceDetailsData[];
}> = ({ services }) => {
  const { classes } = useStyles();

  const [broadband, voice] = useMemo(
    () => [
      services.filter(({ type }) => type === SERVICE_TYPES.BROADBAND),
      services.filter(({ type }) => type === SERVICE_TYPES.VOICE)
    ],
    [services]
  );

  const numRows = useMemo(
    () => (broadband.length > voice.length ? broadband.length : voice.length),
    [broadband.length, voice.length]
  );

  return (
    <Grid container item columnSpacing={2} rowSpacing={0}>
      {[...Array(numRows)].map((_, index) => (
        <Fragment key={`service-${index}`}>
          <ServiceDetailItem service={broadband[index]} />
          <ServiceDetailItem service={voice[index]} rightColumn />
          <Divider variant='fullWidth' className={classes.divider} />
        </Fragment>
      ))}
    </Grid>
  );
};
