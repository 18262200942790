import {
  NOTIFICATIONS,
  SERVICE_TYPES,
  STANDARD_DATE_FORMAT,
  SWITCH_ACTIONS,
  SWITCH_STATES
} from 'app/constants';
import {
  AffectedServiceWithIntent,
  ImplicationsData,
  MatchConfirmation,
  OrderConfirmation,
  OrderRequest,
  ServiceIdentifierValue,
  SwitchingImplications,
  SwitchMatchDetails,
  SwitchMatchResult,
  SwitchOrderFull,
  SwitchOrderNotification
} from 'app/types';
import {
  ServiceDetailsData,
  ServicesData,
  ServicesDataFromSwitchingImplications
} from 'app/types/serviceDetails';
import { SERVICE_TYPES_TYPE, SWITCH_ACTIONS_TYPE } from 'app/types/typesFromEnums';

import { formatDateTime } from './date-utils';
import { formatCurrency } from './format-utils';
import { isOpenOrder } from './order-utils';

export const getServiceTypeLabel = (type: SERVICE_TYPES_TYPE) =>
  type === SERVICE_TYPES.BROADBAND ? 'Broadband' : 'Voice';

// Attempt to make strings reader friendly. Eg: NetworkOperator becomes Network Operator.
export const getIdentifierTypeLabel = (type: string) =>
  type.replace(
    /([^A-Za-z0-9.$])|([A-Z])(?=[A-Z][a-z])|([^\-$.0-9])(?=\$?[0-9]+(?:\.[0-9]+)?)|([0-9])(?=[^.0-9])|([a-z])(?=[A-Z])/g,
    '$2$3$4$5 '
  );

export const getOpenOrderTypeValues = (type: string) => {
  switch (type) {
    case 'custModify':
    case 'CUST_MODIFY':
      return 'Customer initiated service modification in progress';
    case 'cpModify':
    case 'CP_MODIFY':
      return 'Communication provider service modification in progress';
    case 'custCease':
    case 'CUST_CEASE':
      return 'Customer initiated cease in progress';
    case 'cpCease':
    case 'CP_CEASE':
      return 'Communication provider initiated cease in progress';
    default:
      return type;
  }
};

// always returns an array with 2 boolean values (cease, then modify)
export const checkServicesDataOpenOrderTypes = (details?: ServiceDetailsData[]) => [
  details?.some(val =>
    ['custCease', 'CUST_CEASE', 'cpCease', 'CP_CEASE'].includes(
      val.fields.openOrder?.orderType || ''
    )
  ),
  details?.some(val =>
    ['custModify', 'CUST_MODIFY', 'cpModify', 'CP_MODIFY'].includes(
      val.fields.openOrder?.orderType || ''
    )
  )
];

const getIntentFromMatchResult = (
  serviceType: SERVICE_TYPES_TYPE,
  switchAction?: SWITCH_ACTIONS_TYPE,
  servicesToPort: string[] = [],
  serviceIdentifiers: ServiceIdentifierValue[] = []
) =>
  switchAction === SWITCH_ACTIONS.SERVICE_FOUND && serviceType === SERVICE_TYPES.VOICE
    ? servicesToPort
        .map(port => !!serviceIdentifiers.find(({ identifier }) => identifier == port))
        .find(val => val)
      ? 'port'
      : 'cease'
    : 'cease';

const getMatchResultFromSwitchMatchDetails = (
  switchMatchDetails?: SwitchMatchDetails,
  sor?: string
) =>
  sor
    ? switchMatchDetails?.matchResult.switchOrderReference === sor
      ? switchMatchDetails?.matchResult
      : switchMatchDetails?.alternativeSwitchOrders?.find(
          val => val.matchResult.switchOrderReference === sor
        )?.matchResult
    : switchMatchDetails?.matchResult;

export const getServiceDetailsDataFromMatchResults = (
  matchResult?: SwitchMatchResult,
  order?: SwitchOrderFull
) =>
  (matchResult?.services || []).map(val => ({
    type: val.serviceType,
    action: {
      original: val.switchAction,
      intent: getIntentFromMatchResult(
        val.serviceType,
        val.switchAction,
        order?.servicesToPort,
        val.serviceIdentifiers
      ),
      role: order?.role
    },
    fields: {
      fields: (val.serviceIdentifiers || []).map(field => ({
        name: field.identifierType,
        value: field.identifier
      }))
    }
  }));

export const getServicesDataFromMatchConfirmation = (
  { switchProcessDetails, switchMatchConfirmationData }: MatchConfirmation,
  order: SwitchOrderFull
): ServicesData => {
  // the matchResult might be in the alternative orders
  const matchResultToUse = getMatchResultFromSwitchMatchDetails(
    switchMatchConfirmationData?.switchMatchDetails,
    switchProcessDetails?.switchOrderReference || order?.switchOrderReference
  );

  return {
    implicationsSent: switchMatchConfirmationData.implicationsSent || [],
    details: getServiceDetailsDataFromMatchResults(matchResultToUse, order)
  };
};

export const getSORFromNotifications = (notifications: SwitchOrderNotification[] = []) => {
  for (const notification of notifications) {
    if (notification.notificationType === NOTIFICATIONS.OrderConfirmation) {
      const content = notification.content as OrderConfirmation;
      const switchOrderReference = content?.switchOrderConfirmationDetails?.switchOrderReference;
      if (switchOrderReference) {
        return switchOrderReference;
      }
    } else if (notification.notificationType === NOTIFICATIONS.OrderRequest) {
      const content = notification.content as OrderRequest;
      const switchOrderReference = content?.switchProcessDetails?.switchOrderReference;
      if (switchOrderReference) {
        return switchOrderReference;
      }
    }
  }
  return '';
};

const findMatchedService = (
  matchResultToUse: SwitchMatchResult,
  service: AffectedServiceWithIntent
) => {
  return matchResultToUse.services.find(val => {
    if (service.serviceType === 'IAS') {
      return val.serviceIdentifiers?.some(
        ({ identifier }) => identifier === service.serviceDetails.serviceInformation
      );
    } else {
      return (
        val.serviceType === service.serviceType &&
        val.serviceIdentifiers?.some(({ identifier, identifierType }) => {
          const isPartialDnCheck =
            identifierType.toLocaleLowerCase().includes('partial') &&
            `${service.serviceDetails.serviceReferenceType}`.toLocaleLowerCase() === 'dn';

          return isPartialDnCheck
            ? service.serviceDetails.serviceReference.endsWith(identifier)
            : identifier === service.serviceDetails.serviceReference;
        })
      );
    }
  });
};

const getServiceDetailsDataFromPotentiallyImpactedServices = (
  potentiallyImpactedServices: AffectedServiceWithIntent[] = [],
  order: SwitchOrderFull,
  matchResultToUse?: SwitchMatchResult
): ServiceDetailsData[] =>
  potentiallyImpactedServices.map(({ serviceDetails, serviceType }) => {
    const fields = [
      ...(serviceDetails.acpId ? [{ name: 'AcpId', value: serviceDetails.acpId }] : []),
      ...(serviceDetails.serviceReferences
        ? serviceDetails.serviceReferences.map(({ type, value }) => ({
            name: type,
            value: value
          }))
        : []),
      ...(serviceDetails.serviceReference && !serviceDetails.serviceReferences
        ? [
            {
              name: serviceDetails.serviceReferenceType || 'Reference',
              value: serviceDetails.serviceReference
            }
          ]
        : []),
      ...(serviceDetails.intent ? [{ name: 'Intent', value: serviceDetails.intent }] : []),
      ...(serviceDetails.optionToRetain &&
      [SWITCH_STATES.MATCH_REQUESTED, SWITCH_STATES.MATCH_CONFIRMED].includes(
        order.state as SWITCH_STATES
      )
        ? [{ name: 'OptionToRetain', value: `${serviceDetails.optionToRetain}` }]
        : []),
      ...(serviceDetails.minTermExpiry
        ? [
            {
              name: 'MinTermExpiry',
              value: formatDateTime(serviceDetails.minTermExpiry, STANDARD_DATE_FORMAT)
            }
          ]
        : []),
      ...(serviceDetails.maxEtc
        ? [{ name: 'MaxEtc', value: formatCurrency(serviceDetails.maxEtc) }]
        : [])
    ];

    const openOrder =
      serviceDetails.openOrder && isOpenOrder(serviceDetails.openOrder)
        ? serviceDetails.openOrder
        : undefined;

    let action;

    if (matchResultToUse) {
      const serviceFromMatchResult = findMatchedService(matchResultToUse, {
        serviceDetails,
        serviceType
      });

      action = {
        original: serviceFromMatchResult?.switchAction,
        intent:
          serviceDetails.intent ??
          getIntentFromMatchResult(
            serviceType,
            serviceFromMatchResult?.switchAction,
            order.servicesToPort,
            serviceFromMatchResult?.serviceIdentifiers
          ),
        role: order.role
      };
    } else {
      action = {
        original: SWITCH_ACTIONS.OPTION_TO_RETAIN,
        intent:
          serviceDetails.intent ??
          getIntentFromMatchResult(
            serviceType,
            SWITCH_ACTIONS.OPTION_TO_RETAIN,
            order.servicesToPort
          ),
        role: order.role
      };
    }

    return {
      type: serviceType,
      action,
      fields: {
        fields,
        openOrder,
        customFields: (serviceDetails.customFields || [])
          .filter(val => val.type !== '')
          .map(({ type, value }) => ({
            name: type,
            value
          }))
      }
    };
  });

export const getServicesDataFromSwitchingImplications = (
  { switchingImplicationsData }: SwitchingImplications,
  order: SwitchOrderFull,
  selectedSOR?: string
): ServicesDataFromSwitchingImplications => {
  const allSwitchMatchDetails = [
    switchingImplicationsData?.switchMatchDetails.matchResult,
    ...(switchingImplicationsData?.switchMatchDetails.alternativeSwitchOrders?.map(
      ({ matchResult }) => matchResult
    ) || [])
  ];

  const otherServices = switchingImplicationsData?.potentiallyImpactedServices.filter(
    service => !allSwitchMatchDetails.some(item => findMatchedService(item, service))
  );

  return {
    implicationsSent: (switchingImplicationsData?.notifyMethods || []).map(
      val =>
        ({
          sentMethod: val.method,
          sentTo: val.methodData
        }) as ImplicationsData
    ),
    customFields: switchingImplicationsData?.customerAccount?.customFields.filter(
      val => val.type !== ''
    ),
    matchedServicesData: allSwitchMatchDetails
      .map(matchResultToUse => ({
        isSelected: selectedSOR === matchResultToUse?.switchOrderReference,
        switchOrderReference: matchResultToUse?.switchOrderReference,
        details: getServiceDetailsDataFromPotentiallyImpactedServices(
          switchingImplicationsData?.potentiallyImpactedServices.filter(service =>
            matchResultToUse ? findMatchedService(matchResultToUse, service) : true
          ),
          order,
          matchResultToUse
        )
      }))
      .sort((a, b) => Number(b.isSelected) - Number(a.isSelected)),
    otherServicesData: otherServices.map(otherService => ({
      details: getServiceDetailsDataFromPotentiallyImpactedServices([otherService], order)
    }))
  };
};
